import React, { Component } from "react";
import SoundCloud from "components/SoundCloudPlayer";
import Waypoint from "react-waypoint";
import Section from "react-bulma-components/lib/components/section";
import Container from "react-bulma-components/lib/components/container";
import Columns from "react-bulma-components/lib/components/columns";

import { renderText, renderHtml, PrismicLink } from "utils/renderHelpers";
import { renderFluidImage } from "utils/imageHelpers";
import uid from "utils/uid";

import classNames from "classnames/bind";
import styles from "./LectureList.module.scss";

const cx = classNames.bind(styles);

class LectureItem extends Component {
  state = { playerShowing: false };

  constructor(props) {
    super(props);
    this.url = props.soundcloud_embed.embed_url;
  }

  showPlayer = () => {
    this.setState({ playerShowing: true });
  };

  hidePlayer = () => {
    this.setState({ playerShowing: false });
  };

  render() {
    const {
      image,
      name,
      description: override_description,
      soundcloud_embed,
      external_url
    } = this.props;

    let { description, title, embed_url } = soundcloud_embed;
    const hasExternalUrl =
      external_url && external_url.url && external_url.url.length > 0;

    description =
      override_description &&
      override_description.html &&
      override_description.html !== `<p></p>`
        ? override_description
        : description
        ? { html: description }
        : null;

    const has2buttons = hasExternalUrl && embed_url;

    return (
      <Columns.Column
        className={cx({
          column: true,
          playing: this.state.playerShowing === true
        })}
        mobile={{ size: 10 }}
        tablet={{ size: 3 }}
      >
        <Waypoint onLeave={this.hidePlayer}>
          <article className={cx({ listItem: true, has2buttons })}>
            <figure>{image && image.url && renderFluidImage(image)}</figure>
            {renderText(name, "h2")}
            {renderHtml(description)}
            {embed_url && (
              <button
                className={styles.link1}
                title={title}
                onClick={this.showPlayer}
              >
                <span>Listen now</span>
              </button>
            )}
            {hasExternalUrl && (
              <PrismicLink
                link={external_url}
                target={`_blank`}
                rel={`noopener noreferrer`}
                className={has2buttons ? styles.link2 : styles.link1}
                label={{ text: "Read now" }}
              />
            )}
          </article>
        </Waypoint>
        <button
          onKeyUp={e => {
            if (e.which && (e.which === 13 || e.which === 32)) {
              this.hidePlayer();
            }
          }}
          onClick={this.hidePlayer}
          className={cx({
            overlay: true,
            visible: this.state.playerShowing === true
          })}
        />
        {this.state.playerShowing && (
          <SoundCloud url={embed_url} id={`scplayer-${uid()}`} title={title} />
        )}
      </Columns.Column>
    );
  }
}

export default ({ primary, items }) => {
  const { heading, anchor_id = null } = primary;
  const theme = primary.theme || `grey`;
  return (
    <Section
      id={anchor_id}
      className={`${theme} ${cx({ section: true, [theme]: true })}`}
    >
      <Container>
        <Columns mobile multiline>
          <Columns.Column mobile={{ size: 12 }} tablet={{ size: 12 }}>
            {renderText(heading, "h4")}
          </Columns.Column>
          {items.map((item, idx) => (
            <LectureItem key={`litem-${idx}`} {...item} />
          ))}
        </Columns>
      </Container>
    </Section>
  );
};
