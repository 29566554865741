import Columns from "react-bulma-components/lib/components/columns";
import Container from "react-bulma-components/lib/components/container";
import { Link } from "gatsby";
import React from "react";
import Section from "react-bulma-components/lib/components/section";
import classNames from "classnames/bind";
import { firstBy } from "thenby";
import { renderFluidImage } from "utils/imageHelpers";
import { renderText } from "utils/renderHelpers";
import resolveLinkById from "utils/resolveLinkById";
import styles from "./EventFinalistsGrid.module.scss";

const cx = classNames.bind(styles);

export default ({ primary, items, award, year, isProgram = false, heading=null, autoSort=true }) => {
  const { prize_link_label } = primary;
  let awardData = null;
  let slug = null;
  let title = heading|| null;
  if (award) {
    awardData = resolveLinkById(award.raw.id);
  }
  if (awardData) {
    slug = awardData.slug;
    if (!title){
      title = `${year} ${awardData.title}`;
    }
  }
  const finalists = [...items];
  const doSort = finalists.length > 1;
  if (autoSort){
  finalists.sort(
    firstBy((a) => {
      if (!doSort) {
        return 0;
      }
      return a.finalistData && a.finalistData.won === "yes" ? -1 : 0;
    }).thenBy((a, b) => {
      const aname = a.writer.sort_title;
      const bname = b.writer.sort_title;
      if (aname > bname) {
        return 1;
      }
      if (bname > aname) {
        return -1;
      }
      return 0;
    })
  );
  }
  const hasWinner =
    finalists.length === 1
      ? true
      : finalists.filter((a) => a.finalistData && a.finalistData.won === "yes")
          .length > 0;

  return (
    <Section className={`grey ${cx({ section: true, grey: true })}`}>
      <Container>
        <Columns mobile multiline className="is-centered-mobile">
          <Columns.Column mobile={{ size: 12 }} tablet={{ size: 12 }}>
            {renderText(title, "h4")}
          </Columns.Column>
          {finalists.map((item, idx) => {
            const { writer, finalistData } = item;
            const { data, slug } = writer;
            const { first_name, last_name, image } = data;
            const { carousel } = image;
            let book = finalistData ? finalistData.book : null;
            const isWinner =
              finalists.length === 1 ||
              (finalistData && finalistData.won === "yes");
            const isFirstFinalist = idx === 1;
            let title = null;
            let publisher = null;
            if (book) {
              book = book.document[0];
              title = book.data.title;
              publisher = book.data.publisher;
            }
            if (publisher) publisher = publisher.document[0].data;

            return (
              <Columns.Column
                key={`eventfinalists-${idx}`}
                mobile={{ size: isWinner ? 12 : 6 }}
                tablet={{
                  size: 2,
                  offset: hasWinner && isFirstFinalist ? 1 : null,
                }}
                className={styles.cardContainer}
              >
                {isWinner ? (
                  <span className={styles.tag}>Winner</span>
                ) : (
                  <span
                    className={styles.notag}
                    role="none"
                    aria-hidden="true"
                  />
                )}

                <article
                  className={cx({
                    card: true,
                    winner: isWinner === true,
                    finalist: isWinner !== true,
                  })}
                >
                  <figure>{renderFluidImage(carousel)}</figure>
                  <Link
                    to={`/${slug}`}
                    title={`${first_name.text} ${last_name.text}`}
                  >
                    <div className={styles.info}>
                      <span
                        className={styles.name}
                      >{`${first_name.text} ${last_name.text}`}</span>

                      {title
                        ? renderText(
                            title,
                            "span",
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            true,
                            book.data.short_story === "true"
                          )
                        : null}
                      {publisher && publisher.document
                        ? renderText(publisher.document[0].data.name, "p")
                        : null}
                    </div>
                  </Link>
                </article>
              </Columns.Column>
            );
          })}

          <Columns.Column
            mobile={{ size: 12 }}
            tablet={{ size: 12 }}
            className={`has-text-centered`}
          >
            <Link to={`${slug}`} title={title} className={styles.button}>
              {prize_link_label && prize_link_label.text ? (
                renderText(prize_link_label, "span")
              ) : (
                <span>More about the prize</span>
              )}
            </Link>
          </Columns.Column>
        </Columns>
      </Container>
    </Section>
  );
};
